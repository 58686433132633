
import { createTrip, updateTrip } from "@/services/trips.service";
import { getTripById } from "@/services/trips.service";
import { defineComponent } from "@vue/runtime-core";
export default defineComponent({
  data() {
    return {
      name: "",
      description: "",
      startDate: undefined as string | undefined,
      endDate: undefined as string | undefined,
    };
  },

  async created(): Promise<void> {
    if (this.tripId) {
      const trip = await getTripById(this.tripId);
      this.name = trip.name;
      this.description = trip.description;
      this.startDate = this.formatDate(trip.startDate);
      this.endDate = this.formatDate(trip.endDate);
    }
  },

  methods: {
    async createTrip() {
      if (this.formIsValid) {
        await createTrip(
          this.name,
          this.description,
          this.startDate!,
          this.endDate!
        );

        this.navigateBack();
      }
    },

    async updateTrip() {
      if (this.formIsValid) {
        await updateTrip(
          this.tripId!,
          this.name,
          this.description,
          this.startDate!,
          this.endDate!
        );
        this.navigateBack();
      }
    },

    resetForm(): void {
      this.name = "";
      this.description = "";
      this.startDate = undefined;
      this.endDate = undefined;
    },

    navigateBack(): void {
      this.resetForm();
      this.$router.push("/");
    },

    formatDate(dateString: string): string {
      const date = new Date(dateString);
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    },
  },

  computed: {
    formIsValid(): boolean {
      return (
        this.name.trim().length > 0 &&
        this.startDate !== undefined &&
        this.endDate !== undefined
      );
    },

    tripId(): string | undefined {
      return (this.$route.params.id as string) || undefined;
    },
  },
});
